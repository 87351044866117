import React from "react"
import { Helmet } from "react-helmet"
import { useLocalization } from "gatsby-theme-i18n"

const SEO = () => {
  const { locale } = useLocalization()
  
  return (
    <div>
      <Helmet>
        <title>آي بالم - تكنولوجيا المعلومات و التحول الرقمي</title>
        <meta
          name="description"
          content="آي بالم شركة متخصصة في تكنولوجيا المعلومات و التحول الرقمي. نساعد عملاءنا في رحلة التحول الرقمي عبر تقديم الإستشارات ووضع الإستراجيات الرقمية. كما يعمل فريقنا على إنشاء الحلول الرقمية من مواقع الواب و البرمجيات الذكية وتطبيقات الهاتف الذكي. "
        />
        <meta
          name="og:title"
          property="og:title"
          content="آي بالم - تكنولوجيا المعلومات و التحول الرقمي"
        ></meta>
        <meta
          name="og:description"
          property="og:description"
          content="آي بالم شركة متخصصة في تكنولوجيا المعلومات و التحول الرقمي. نساعد عملاءنا في رحلة التحول الرقمي عبر تقديم الإستشارات ووضع الإستراجيات الرقمية. كما يعمل فريقنا على إنشاء الحلول الرقمية من مواقع الواب و البرمجيات الذكية وتطبيقات الهاتف الذكي. "
        />
        <meta
          name="og:type"
          property="og:type"
          content="website"
        ></meta>
        <meta
          property="og:image"
          content="http://www.ipalm.tn/banner-seo-image.png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="http://www.ipalm.tn"
        ></meta>
        <meta
          name="og:local"
          property="og:local"
          content="ar_AR"
        ></meta>
        <meta
          name="og:locale:alternate"
          property="og:locale:alternate"
          content="fr_FR"
        ></meta>
        <meta
          name="og:locale:alternate"
          property="og:locale:alternate"
          content="en_US"
        ></meta>
        <meta
        name="twitter:title"
        property="twitter:title"
        content="آي بالم - تكنولوجيا المعلومات و التحول الرقمي">
        </meta>
        <meta
          name="twitter:description"
          property="twitter:description"
          content="آي بالم شركة متخصصة في تكنولوجيا المعلومات و التحول الرقمي. نساعد عملاءنا في رحلة التحول الرقمي عبر تقديم الإستشارات ووضع الإستراجيات الرقمية. كما يعمل فريقنا على إنشاء الحلول الرقمية من مواقع الواب و البرمجيات الذكية وتطبيقات الهاتف الذكي. "
        />
        <meta
          name="twitter:card"
          content="آي بالم - تكنولوجيا المعلومات و التحول الرقمي"
        ></meta>
        <meta
          property="twitter:image"
          content="http://www.ipalm.tn/banner-seo-image.png"
        />
        <link rel="canonical" href="http://www.ipalm.tn/"></link>
      </Helmet>
    </div>
  )
}

export default SEO
