import React from 'react'
import { useIntl } from 'react-intl'
import { RecoilRoot } from 'recoil'
import GoTop from './GoTop'
import SEO from './SEO'

const Layout = ({ children }) => {
    const intl = useIntl()
    
    return (
        <RecoilRoot>
            
            <SEO />
            <div style={intl.locale === 'ar' ? {direction: 'rtl'} : {direction: 'ltr'}}>
            {children}
            </div>
            <GoTop scrollStepInPx="100" delayInMs="10.50" />
            
        </RecoilRoot>
    )
}

export default Layout
